//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dark from '@amcharts/amcharts4/themes/dark';
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline";
import { mapGetters, mapActions } from "vuex";
import eventBus from "@/plugins/eventBus";
import moment from "moment";
import * as _ from "underscore";
am4core.useTheme(am4themes_animated);
export default {
  name: "messages",
  data() {
    return {
      showChart: true,
      showInnerLoading: false,
      isCall: false,
      busyDay: "",
      busyHour: "",
      sessions_data: [],
      heatmap_data: [],
      dayHoursStats: 0,
      nightHoursStats: 0,
      mostCommonTopic: [],
      chartType: "bubble"
    };
  },
  computed: {
    ...mapGetters({
      getFilterDate: "getFilterDate",
      currentAIAccount: "getCurrentAIAccount",
      currentUser: "getCurrentUser",
      darkTheme: "getDarkTheme"
    }),
    totalSessions() {
      if (this.sessions_data.length > 1) {
        return _.find(this.sessions_data, obj => obj.rowTitle == "TOTSESSIONS").Total;
      }
    },
    totalMessages() {
      if (this.sessions_data.length > 1) {
        return _.find(this.sessions_data, obj => obj.rowTitle == "TOTMESSAGES").Total;
      }
    },
    mostMessages() {
      if (this.sessions_data.length > 1) {
        let messagesInfo =_.find(this.sessions_data, obj => obj.rowTitle == "MAXMESSAGES")
        delete messagesInfo.Total
        delete messagesInfo.rowTitle
        return _.max(_.values(messagesInfo))
      }
    },
    noOfDays() {
      const start = moment(this.getFilterDate.start, "DD-MM-YYYY");
      const end = moment(this.getFilterDate.end, "DD-MM-YYYY");
      return end.diff(start, "days") + 1;
    },
    averageDailyMessages() {
      if (this.sessions_data.length > 0) {
        return (this.totalMessages / this.noOfDays).toFixed(2);
      }
      return 0;
    },
    averageWeeklyMessages() {
      if (this.sessions_data.length > 0 && this.noOfDays > 7) {
        let numberofweeks = this.noOfDays / 7;
        return (this.totalMessages / numberofweeks).toFixed(2);
      }
      return this.totalMessages;
    },
    averageMonthlyMessages() {
      if (this.sessions_data.length > 0 && this.noOfDays > 30) {
        let numberofMonths = this.noOfDays / 30;
        return (this.totalMessages / numberofMonths).toFixed(2);
      }
      return this.totalMessages;
    },
    averageMessagePerSession() {
      if (this.sessions_data.length > 0) {
        return (this.totalMessages / this.totalSessions).toFixed(2);
      }
      return 0;
    }
  },
  methods: {
    ...mapActions({
      requestConversationsTopicParameters: "requestConversationsTopicParameters",
      requestAudienceBehaviour: "requestAudienceBehaviour",
      requestSessionsOverview: "requestSessionsOverview"
    }),
    onDateUpdate(date) {
      const that = this;
      if (that.isValid(date.start) && that.isValid(date.end)) {
        that.isCall = false;
        const data = {
          aiAccount: that.currentAIAccount.id,
          startdate: date.start,
          enddate: date.end,
          from: "onDateUpdate"
        };
        that.updateAISesions(data);
        that.updateBehaviourData(data);
      } else {
        console.log("invalid date");
      }
    },
    generateBubbleBasedChart() {
      const that = this;
      if(this.chart){
        this.chart.dispose()
      }
      if(this.darkTheme === true){
        am4core.useTheme(am4themes_dark);
      }else{
        am4core.unuseTheme(am4themes_dark)
      }
      const chart = am4core.create(this.$refs.BubbleBasedChart, am4charts.XYChart);
      chart.maskBullets = false;

      const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      const yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

      yAxis.dataFields.category = "weekday";
      xAxis.renderer.minGridDistance = 40;
      xAxis.dataFields.category = "hour";

      xAxis.renderer.grid.template.disabled = true;
      yAxis.renderer.grid.template.disabled = true;
      xAxis.renderer.axisFills.template.disabled = true;
      yAxis.renderer.axisFills.template.disabled = true;
      yAxis.renderer.ticks.template.disabled = true;
      xAxis.renderer.ticks.template.disabled = true;

      yAxis.renderer.inversed = true;

      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryY = "weekday";
      series.dataFields.categoryX = "hour";
      series.dataFields.value = "value";
      series.columns.template.disabled = true;
      series.sequencedInterpolation = true;
      // series.defaultState.transitionDuration = 3000;

      const bullet = series.bullets.push(new am4core.Circle());
      bullet.tooltipText = "{weekday}, {hour}: {value.workingValue.formatNumber('#.')}";
      bullet.strokeWidth = 3;
      bullet.stroke = am4core.color("#ffffff");
      bullet.strokeOpacity = 0;

      bullet.adapter.add("tooltipY", (tooltipY, target) => -target.radius + 1);

      series.heatRules.push({
        property: "radius",
        target: bullet,
        min: 2,
        max: 40
      });

      bullet.hiddenState.properties.scale = 0.01;
      bullet.hiddenState.properties.opacity = 1;

      const hoverState = bullet.states.create("hover");
      hoverState.properties.strokeOpacity = 1;
      chart.data = that.heatmap_data;
      // Enable export
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.filePrefix = `EVO_Dynamics_report-${that.currentAIAccount.name}-Users Messages-${that.getFilterDate.start}-to-${that.getFilterDate.end}`;
      this.chart = chart;
    },
    generateSerpentineStepline() {
      const that = this;

      if(this.chart){
        this.chart.dispose()
      }
      if(this.darkTheme === true){
        am4core.useTheme(am4themes_dark);
      }else{
        am4core.unuseTheme(am4themes_dark)
      }
      const chart = am4core.create(this.$refs.SerpentineStepline, am4plugins_timeline.SerpentineChart);
      chart.levelCount = 3;
      chart.curveContainer.padding(50, 20, 50, 20);
      const data = [];
      const filteredDate = that.getFilterDate.start.split("-");
      for (let i = 0; i < that.Serpentine_Stepline_data.length; i++) {
        const { value } = that.Serpentine_Stepline_data[i];
        data.push({
          date: new Date(
            parseInt(filteredDate[2]),
            parseInt(filteredDate[1]) - 1,
            parseInt(filteredDate[0]),
            parseInt(that.Serpentine_Stepline_data[i].flag)
          ),
          value
        });
      }
      chart.data = data;

      const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;

      dateAxis.renderer.line.disabled = true;
      dateAxis.cursorTooltipEnabled = false;
      dateAxis.minZoomCount = 5;

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.innerRadius = -50;
      valueAxis.renderer.radius = 50;
      chart.seriesContainer.zIndex = -1;

      const series = chart.series.push(new am4plugins_timeline.CurveStepLineSeries());
      series.fillOpacity = 0.3;
      series.dataFields.dateX = "date";
      series.dataFields.valueY = "value";
      series.tooltipText = "{valueY}";
      series.tooltip.pointerOrientation = "vertical";
      series.tooltip.background.fillOpacity = 0.7;
      series.fill = chart.colors.getIndex(3);
      series.strokeWidth = 2;

      chart.cursor = new am4plugins_timeline.CurveCursor();
      chart.cursor.xAxis = dateAxis;
      chart.cursor.yAxis = valueAxis;
      chart.cursor.lineY.disabled = true;
      // disable zooming
      chart.cursor.behavior = "none";
      // Enable export
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.filePrefix = `EVO_Dynamics_report-${that.currentAIAccount.name}-Users Messages-${that.getFilterDate.start}-to-${that.getFilterDate.end}`;
      this.chart = chart;
    },
    updateAISesions(data) {
      const that = this;
      if (!that.isCall) {
        that.showChart = true;
        that.showInnerLoading = true;
        that.isCall = true;
        if (this.sessionChart) {
          this.sessionChart.dispose();
        }
        that.updateMostCommonTopic(data);
        that.requestSessionsOverview(data).then(resp => {
            if (resp.final_data.length > 0) {
              that.sessions_data = resp.final_data;
              that.generateHeatMapData(resp.time_data);
              that.generateByDayNighttats(resp.hour_messages);
            }
          })
          .catch(err => {
            that.showChart = false;
          })
          .finally(() => {
            setTimeout(() => {
              that.isCall = false;
              that.showInnerLoading = false;
            }, 1000);
          });
      }
    },
    updateBehaviourData(data) {
      if (this.chart) {
        this.chart.dispose();
      }
      const that = this;
      that.showChart = true;
      that.showInnerLoading = true;
      this.requestAudienceBehaviour(data)
        .then(res => {
          if (res.result.length > 0) {
            that.heatmap_data = res.result;
            that.Serpentine_Stepline_data = res.messagesCount;
            setTimeout(() => {
              if(_.has(that.$route.query , 'chart') && _.contains(['bubble' , 'path'] , that.$route.query.chart)){
                that.chartType = that.$route.query.chart
              }
              if (that.chartType == "bubble") {
                that.generateBubbleBasedChart();
              } else {
                that.generateSerpentineStepline();
              }
            }, 100);
          } else {
            setTimeout(() => {
              that.showInnerLoading = false;
              that.showChart = false;
            }, 100);
          }
        })
        .finally(() => {
          setTimeout(() => {
            that.showInnerLoading = false;
          }, 1000);
        });
    },
    updateMostCommonTopic(data) {
      const that = this;
      that.requestConversationsTopicParameters(data).then(res => {
        let sorted = _.sortBy(res.topicData, "value").reverse();
        that.mostCommonTopic = sorted.slice(0, 3);
      });
    },
    generateHeatMapData(time_data) {
      const that = this;
      const heatmap_data_sorted = [];
      const days_flag = [
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
        "SUNDAY"
      ];
      _.forEach(time_data, record => {
        if (_.contains(days_flag, record.rowTitle)) {
          const weekday = record.rowTitle;
          delete record.rowTitle;
          delete record.total;
          for (const hour in record) {
            if (hour !== "Total") {
              heatmap_data_sorted.push({
                weekday,
                hour,
                value: record[hour]
              });
            }
          }
        }
      });
      that.heatmap_data = heatmap_data_sorted;
      that.generateBusyStats();
    },
    generateByDayNighttats(hour_messages) {
      const that = this;
      const hour_dataObj = hour_messages[0];
      const nightHoursStats = [];
      const dayHoursStats = [];
      for (const hourNumber in hour_dataObj) {
        if (hourNumber !== "rowTitle") {
          if (hourNumber >= 9 && hourNumber < 18) {
            dayHoursStats.push(hour_dataObj[hourNumber]);
          } else {
            nightHoursStats.push(hour_dataObj[hourNumber]);
          }
        }
      }
      that.dayHoursStats = _.reduce(dayHoursStats, (memo, num) => memo + num, 0);
      that.nightHoursStats = _.reduce(nightHoursStats, (memo, num) => memo + num, 0);
    },
    generateBusyStats() {
      const that = this;
      const { heatmap_data } = that;
      const groupByDay = _.groupBy(heatmap_data, obj => obj.weekday);
      const groupByHour = _.groupBy(heatmap_data, obj => obj.hour);
      const byDayStats = [];
      for (const day in groupByDay) {
        const weekday = day;
        const day_data = _.pluck(groupByDay[day], "value");
        const sum = _.reduce(day_data, (memo, num) => memo + num, 0);
        byDayStats.push({
          weekday,
          count: sum
        });
      }
      const busyDay = _.max(byDayStats, day => day.count);
      that.busyDay = busyDay;
      const byHourStats = [];
      for (const hour in groupByHour) {
        if (hour !== "total") {
          const hour_data = _.pluck(groupByHour[hour], "value");
          const sum = _.reduce(hour_data, (memo, num) => memo + num, 0);
          byHourStats.push({
            hour,
            count: sum
          });
        }
      }
      const busyHour = _.max(byHourStats, day => day.count).hour;
      that.busyHour = busyHour;
    },
    onAccountUpdate(account) {
      const that = this;
      const data = {
        aiAccount: account.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
        from: "onAccountUpdate"
      };
      that.isCall = false;
      that.updateAISesions(data);
        that.updateBehaviourData(data);
    },
    isValid(value) {
      if (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
      ) {
        return false;
      }
      return true;
    },
    chartFullScreen(id){
      // let chart_card = document.getElementsByClassName(id)
      // if(chart_card.length > 0){
      //    chart_card[0].style.border = '1px solid red'
      //    chart_card[0].style.width = '100vw !important' 
      //     chart_card[0].style.height = '100vh  !important'
      // }
    }
  },
  watch: {
    showChart(newVal) {
      const that = this;
      if (newVal == false) {
        if (that.chart) {
          that.chart.dispose();
        }
      }
    },
    chartType(newVal) {
      const that = this;
      if (that.chart) {
        that.chart.dispose();
      }
      setTimeout(() => {
        if (that.chartType == "bubble") {
          that.generateBubbleBasedChart();
        } else {
          that.generateSerpentineStepline();
        }
      }, 100);
    },
    darkTheme(){
      const that = this;
      if (that.chart) {
        that.chart.dispose();
      }
      setTimeout(() => {
        if (that.chartType == "bubble") {
          that.generateBubbleBasedChart();
        } else {
          that.generateSerpentineStepline();
        }
      }, 100);
    }
  },
  mounted() {
    const that = this;
    const data = {
      aiAccount: that.currentAIAccount.id,
      startdate: that.getFilterDate.start,
      enddate: that.getFilterDate.end,
      from: "mounted"
    };
    that.updateAISesions(data);
    that.updateBehaviourData(data);
  },
  created() {
    eventBus.$on("updateDate", this.onDateUpdate);
    eventBus.$on("updateAIAccount", this.onAccountUpdate);
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
    eventBus.$off("updateDate", this.onDateUpdate);
    eventBus.$off("updateAIAccount", this.onAccountUpdate);
  },
  destroyed() {
    eventBus.$off("updateDate", this.onDateUpdate);
    eventBus.$off("updateAIAccount", this.onAccountUpdate);
  }
};
